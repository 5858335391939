import React, { PureComponent } from 'react';
import { Form, Row, Col } from 'antd';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { UserStatus } from '@utils/enums/UserStatus';
import SEO from '@components/SEO';
import IconArrow from '@icons/IconArrow';
import { InputType } from '@utils/enums/InputType';
import { Role } from '@utils/enums/Role';
import Input from '@components/Input';
import constant from '@utils/constant';
import Footer from '@views/default-layout/footer/Footer';
import { profileApi } from '@apis/profileApi';
import { tokenUtil } from '@utils/token';
import { message } from '@components/Message';
import { GET_ME__FULFILLED } from '../../constants/actions';
import qs from 'query-string';
import { setError } from '@views/error/ErrorBoundary/ErrorDetector';
import Error from '@views/error/ErrorBoundary/Error';

const formDefinitions = [
   {
      name: 'username',
      placeholder: 'Email',
      inputTitle: 'Email',
      rules: [
         {
            required: true,
            message: 'Please input your Email.',
         },
      ],
   },
   {
      name: 'password',
      type: InputType.PASSWORD,
      placeholder: 'Password',
      inputTitle: 'Password',
      rules: [
         {
            required: true,
            message: 'Please input your Password.',
         },
      ],
   },
];

class index extends PureComponent {
   state = {
      deviceType: 'WEB_APP',
      submitting: false,
      loading: true,
      nextUrl: '',
   };
   _mounted = false;

   componentWillUnmount() {
      this._mounted = false;
   }

   getNextUrl = () => {
      const { location } = this.props;
      const params = qs.parse(location.search);
      console.log(this.props);
      if (params[constant.backUrlQuery]) {
         const nextUrl = `${decodeURIComponent(params[constant.backUrlQuery])}`.replace(
            location.origin,
            ''
         );
         this.setState({
            nextUrl: nextUrl || '',
         });
      }
   };

   componentDidMount() {
      this._mounted = true;
      this.getNextUrl();
      this.checkUserLoggedIn();
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.loading !== this.props.loading) {
         this.checkUserLoggedIn();
      }
   }

   checkUserLoggedIn = () => {
      const { loading, userProfile } = this.props;
      if (navigator && !navigator.cookieEnabled) {
         // setTimeout(() => {
         setError(Error.DISABLED_COOKIES);
         // }, 300);
         return true;
      }
      if (!loading) {
         if (userProfile && userProfile.status === UserStatus.AVAILABLE) {
            if (userProfile.active) {
               navigate(constant.ROUTE_HOME);
               return;
            } else {
               navigate(constant.ROUTE_ACTIVATE);
               return;
            }
         }
         this.setState({ loading: false });
      }
   };

   onFinish = async (values) => {
      const deviceId = await tokenUtil.getDeviceId();
      let body = {
         username: values?.username,
         password: values?.password,
         device_type: this.state.deviceType,
         device_id: deviceId,
         device_token: this.props.deviceToken || '',
      };
      await this.setState({ submitting: true });
      const responseData = await profileApi.login(body);
      if (responseData.isSuccess && responseData.data) {
         const { token, user } = responseData.data;
         await tokenUtil.setCookie(constant.TOKEN_VARIABLE, token || '');
         this.props.dispatch({
            type: GET_ME__FULFILLED,
            payload: user,
         });
         if (this.state.nextUrl) {
            navigate(this.state.nextUrl);
            return;
         }
         // if (user.role_name === Role.MANAGER || user.role_name === Role.SYSTEM) {
         //    navigate(constant.ROUTE_ADMIN);
         //    return;
         // } else {
         navigate(constant.ROUTE_HOME);
         return;
         // }
      } else {
         message.error(responseData.message);
      }
      if (this._mounted) {
         this.setState({ submitting: false });
      }
   };

   render() {
      const { loading, submitting } = this.state;
      return (
         <>
            <SEO title={'Sign In'} />
            {!loading && (
               <>
                  <div className="login-register">
                     <div className="section-title">
                        <span className="section-title-name animate__animated animate__fadeIn">
                           SIGN IN
                           <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                              <IconArrow />
                           </span>
                        </span>
                     </div>
                     <Row gutter={30}>
                        <Col lg={10} md={12} sm={24} xs={24}>
                           <Form className="submit-form" onFinish={this.onFinish}>
                              {formDefinitions.map((item) => {
                                 return (
                                    <Form.Item
                                       key={item.name}
                                       name={item.name}
                                       dependencies={item.dependencies}
                                       rules={item.rules || []}
                                    >
                                       <Input
                                          type={item.type}
                                          name={item.name}
                                          inputTitle={item.inputTitle}
                                          placeholder={item.placeholder}
                                       />
                                    </Form.Item>
                                 );
                              })}
                              <Form.Item>
                                 <button
                                    className="common-button"
                                    type="primary"
                                    disabled={submitting}
                                 >
                                    {submitting ? 'Signing In' : 'Sign In'}
                                 </button>
                              </Form.Item>
                           </Form>
                           <Link
                              to={constant.ROUTE_RESET_PASSWORD}
                              className="link-forgot-pass"
                           >
                              Forgot Password?
                           </Link>
                           <Link to={constant.ROUTE_REGISTER} className="link-register">
                              Don't have an Account? Sign Up
                           </Link>
                        </Col>
                     </Row>
                  </div>
                  <Footer />
               </>
            )}
         </>
      );
   }
}

const mappingState = (state, ownProps) => {
   return {
      loading: state.profile.loading,
      userProfile: state.profile.data,
      deviceToken: state.common.deviceToken,
   };
};

export default connect(mappingState)(index);
