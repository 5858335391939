import React, { PureComponent } from 'react';

class IconArrow extends PureComponent {
   render() {
      return (
         <svg
            width={58}
            height={58}
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M40.9289 48L0.464466 7.53553L7.53553 0.464466L48 40.9289V0H58V53V58H53H0V48H40.9289Z"
               fill="currentColor"
            />
         </svg>
      );
   }
}

export default IconArrow;